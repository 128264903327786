@use '@angular/material' as mat;

@mixin public-status-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);

  trader-public-status {
    background-color: mat.get-color-from-palette($accent, 500);
    color: mat.get-contrast-color-from-palette($warn, 500);
  }
}
