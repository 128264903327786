@use '@angular/material' as mat;

@import 'styles/variables';
@import 'styles-reset';
@import 'styles/utility-classes';
@import 'styles/tailwind';
@import 'styles/trading';
@import 'styles/toastr-overrides';
@import 'styles/dialog';
@import 'styles/ngx-slider';
@import 'styles/global-overrides';
@import 'styles/table';
@import 'styles/color-classes';
@import 'styles-reset.scss-theme';


@import './themes/new-default-theme';
@import './themes/default-theme';
@import './themes/dark-theme';
@import './app/app.component.scss-theme';
@import './app/shared/components/exchange/symbol-toolbar/theme';

@import './app/shared/components/container-widgets/widget-box/theme';
@import './app/pages/day-trading/day-trading/theme';
@import './app/pages/batch-orders-templates/theme';
@import './app/pages/login/theme';
@import './app/shared/directive/theme';
@import './app/shared/components/bottom-drawer/theme';
@import './app/shared/components/exchange/server-order/theme';
@import './app/shared/components/exchange/server-orders-table/theme';
@import './app/shared/components/exchange/batch-orders-table/theme';
@import 'app/shared/components/portfolio-overview-dropdown/theme';
@import 'app/shared/components/performence-overview/theme';
@import 'app/shared/components/grow-indicator/theme';
@import 'app/shared/components/exchange/exchange-order-list/theme';
@import 'app/shared/components/exchange/symbol-server-order-list/theme';
@import 'app/shared/components/exchange/favourite-tickers-list/theme';
@import 'app/shared/components/exchange/batch-order-confirmation-content/theme';
@import 'app/shared/components/exchange/order-side/theme';
@import 'app/shared/components/exchange/order-status/theme';
@import 'app/shared/components/exchange/public-status/theme';
@import 'app/shared/components/exchange/batch-order-status/theme';
@import 'app/shared/components/order-tags/theme';
@import 'app/shared/components/exchange/symbol/symbol-batch-server-order-list/theme';

@mixin custom-components-theme($theme) {
  @include color-classes($theme);
  @include styles-reset-theme($theme);
  @include app-component-theme($theme);
  @include login-theme($theme);
  @include symbol-toolbar-theme($theme);
  @include trader-table-theme($theme);
  @include ngx-slider-theme($theme);
  @include widget-box-theme($theme);
  @include day-trading-theme($theme);
  @include batch-order-templates-theme($theme);
  @include portfolio-overview-dropdown-theme($theme);
  @include performance-overview-theme($theme);
  @include directives-theme($theme);
  @include bottom-drawer-theme($theme);
  @include server-order-component-theme($theme);
  @include grow-indicator($theme);
  @include trader-exchange-orders-list-theme($theme);
  @include trader-symbol-server-order-list-theme($theme);
  @include trader-order-tags-theme($theme);
  @include favourite-ticker-list-theme($theme);
  @include order-side-theme($theme);
  @include order-status-theme($theme);
  @include batch-order-status-theme($theme);
  @include public-status-theme($theme);
  @include batch-order-confirmation-content-theme($theme);
  @include server-orders-table-theme($theme);
  @include batch-orders-table-theme($theme);
  @include trader-symbol-batch-server-order-list-theme($theme);
}
@include mat.core();
@include mat.all-component-themes($ifelse-light-theme);
@include mat.all-component-typographies($custom-typography);

.default-theme {
  @include custom-components-theme($ifelse-light-theme);
  @include light-improvements($ifelse-light-theme);
}

.black-theme {
  @include mat.all-component-colors($ifelse-dark-theme);
  @include mat.core-color($ifelse-dark-theme);
  @include custom-components-theme($ifelse-dark-theme);
  @include dark-form-controls();
  @include dark-improvements($ifelse-dark-theme);
}
