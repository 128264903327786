html {
  box-sizing: border-box;
  font-size: 14px;
  height: 100%;
}
body {
  height: 100%;
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

* {
  &:active,
  :focus {
    outline: none !important;
  }
}

code {
  white-space: nowrap;
  border-radius: 10px;
  padding: 0 8px 1px 8px;
}

.mat-mdc-menu-content {
  fa-icon {
    position: relative;
    top: 2px;
    left: 4px;
  }
}

p {
  a {
    border-bottom: 3px solid;
  }
}
.hidden {
  margin: 0;
  padding: 0;
  z-index: -1000;
  height: 0;
  opacity: 0;
  visibility: hidden;
  display: block;
}
