@use '@angular/material' as mat;

@mixin login-theme($theme) {
  $background: map-get($theme, bg);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, bg);

  #login-card {
    background: mat.get-color-from-palette($background, card);
    box-shadow: 3px 3px 70px rgba(0, 0, 0, 0.2);
  }
}
