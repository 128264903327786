@use '@angular/material' as mat;
@import '../../../../styles/variables';

@mixin buyColors($color) {
  background-color: mat.get-color-from-palette($color);
  color: mat.get-color-from-palette($color, '600-contrast');
}

@mixin day-trading-theme($theme) {
  $background: map-get($theme, bg);
  $foreground: map-get($theme, foreground);
  $success: map-get($theme, success);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  trader-footer-navigation {
    .mat-toolbar {
      background-color: mat.get-color-from-palette($background, card) !important;
    }
    .divider {
      border-color: mat.get-color-from-palette($foreground, divider);
    }
  }

  trader-batch-order {
    .buy-button {
      @include buyColors($success);
    }
    .sell-button {
      @include sellColors($warn);
    }
  }

  trader-day-trading {
    .filter-counter {
      background-color: mat.get-color-from-palette($accent);
      color: mat.get-color-from-palette($accent, '500-contrast');
    }

    .filter-group .divider {
      border-color: rgba(180, 180, 180, 0.22);
    }
    .title-index {
      background-color: mat.get-color-from-palette($success, 500);
      color: mat.get-contrast-color-from-palette($success, 500);
    }
  }
  trader-batch-template-order-selector {
    .select-privacy {
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-checked {
        background-color: mat.get-color-from-palette($accent);
        color: mat.get-color-from-palette($accent, '500-contrast');
      }
    }
  }
}
