@use '@angular/material' as mat;

@mixin sellColors($color) {
  background-color: mat.get-color-from-palette($color);
  color: mat.get-color-from-palette($color, '600-contrast');
}

@mixin buyColors($color) {
  background-color: mat.get-color-from-palette($color);
  color: mat.get-color-from-palette($color, '600-contrast');
}

@mixin color-classes($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);
  $background: map-get($theme, bg);
  $foreground: map-get($theme, foreground);

  .es-secondary-text,
  .muted {
    color: map-get($foreground, secondary-text) !important;
  }
  .muted-background {
    opacity: 0.7;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }

  .accent-panel {
    --mdc-dialog-container-color: map-get($background, dialog-accent);
    .mat-mdc-dialog-container {
      background-color: map-get($background, dialog-accent) !important;
      .mdc-dialog__surface {
        box-shadow: none !important;
      }
    }
  }

  .title-index {
    width: 25px;
    height: 25px;
    padding: 3px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .warn,
  .red {
    background-color: map-get($warn, 500);
    color: mat.get-contrast-color-from-palette($warn, 500);
  }

  .green {
    background-color: mat.get-color-from-palette($success, 500);
    color: mat.get-contrast-color-from-palette($success, 500);
  }
  .accent {
    background-color: mat.get-color-from-palette($accent);
    color: mat.get-contrast-color-from-palette($accent, 500);
  }

  button.spinner:before {
    border-color: rgba(255, 255, 255, 0.7);
    border-top-color: rgba(0, 0, 0, 0.4);
  }

  .buy-button {
    @include buyColors($success);
  }

  .sell-button {
    @include sellColors($warn);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-checked {
    &.buy-button {
      @include buyColors($success);
    }

    &.sell-button {
      @include sellColors($warn);
    }
  }
}
