@use '@angular/material' as mat;

@mixin order-status-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);

  trader-order-status {
    &.NEW {
      background-color: mat.get-color-from-palette($success, 300);
      color: mat.get-contrast-color-from-palette($success, 300);
    }
    &.PLACED {
      background-color: mat.get-color-from-palette($success, 200);
      color: mat.get-contrast-color-from-palette($success, 200);
    }
    &.PROCESSING {
      background-color: mat.get-color-from-palette($accent, 800);
      color: mat.get-contrast-color-from-palette($accent, 800);
    }
    &.PARTIALLY_FILLED {
      background-color: mat.get-color-from-palette($success, 500);
      color: mat.get-contrast-color-from-palette($success, 500);
    }
    &.FILLED {
      background-color: mat.get-color-from-palette($success, 400);
      color: mat.get-contrast-color-from-palette($success, 500);
    }
    &.CANCELED {
      background-color: mat.get-color-from-palette($primary, 500);
      color: mat.get-contrast-color-from-palette($primary, 500);
    }
    &.PENDING_CANCEL {
      background-color: mat.get-color-from-palette($primary, 300);
      color: mat.get-contrast-color-from-palette($primary, 300);
    }
    &.REJECTED {
      background-color: mat.get-color-from-palette($warn, 300);
      color: mat.get-contrast-color-from-palette($warn, 300);
    }
    &.SERVER_REJECTED_NO_BALANCE {
      background-color: mat.get-color-from-palette($warn, 500);
      color: mat.get-contrast-color-from-palette($warn, 500);
    }
    &.SERVER_REJECTED_ERROR {
      background-color: mat.get-color-from-palette($warn, 700);
      color: mat.get-contrast-color-from-palette($warn, 700);
    }
    &.EXPIRED {
      background-color: mat.get-color-from-palette($warn, 200);
      color: mat.get-contrast-color-from-palette($warn, 200);
    }
    &.BATCH_PROCESSED {
      background-color: mat.get-color-from-palette($success, 800);
      color: mat.get-contrast-color-from-palette($success, 800);
    }
  }
}
