.w-100 {
  width: 100%;
}

@for $i from 0 through 8 {
  .m-#{$i} {
    margin: 0.25em * $i !important;
  }
  .mt-#{$i} {
    margin-top: 0.25em * $i !important;
  }
  .mb-#{$i} {
    margin-bottom: 0.25em * $i !important;
  }
  .ml-#{$i} {
    margin-left: 0.25em * $i !important;
  }
  .mr-#{$i} {
    margin-right: 0.25em * $i !important;
  }

  .p-#{$i} {
    padding: 0.25em * $i !important;
  }
  .pt-#{$i} {
    padding-top: 0.25em * $i !important;
  }
  .pb-#{$i} {
    padding-bottom: 0.25em * $i !important;
  }
  .pl-#{$i} {
    padding-left: 0.25em * $i !important;
  }
  .pr-#{$i} {
    padding-right: 0.25em * $i !important;
  }
}

.es-content-container {
  height: calc(100vh - 250px);
  margin-bottom: 2em;
  overflow-y: auto;
  overflow-x: hidden;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

button.spinner {
  padding-right: 40px;
  transition: padding-right 400ms;
}

button.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid;
  animation: spinner 0.8s linear infinite;
}

.bullet {
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 50%;
  font-size: 0.8em;
}
