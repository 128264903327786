@use '@angular/material' as mat;

@mixin styles-reset-theme($theme) {
  $primary: mat.get-color-from-palette($theme, primary);
  $accent: mat.get-color-from-palette($theme, accent);
  $foreground: mat.get-color-from-palette($theme, foreground);

  color: mat.get-color-from-palette($foreground, text);
  a {
    color: mat.get-color-from-palette($foreground, text);

    &:hover {
      color: mat.get-color-from-palette($accent);
    }
  }

  code {
    color: mat.get-color-from-palette($accent, lighter-contrast);
    background-color: mat.get-color-from-palette($accent, lighter);
  }

  p {
    a {
      color: mat.get-color-from-palette($accent);

      &:hover {
        color: mat.get-color-from-palette($accent, darker);
      }
    }
  }
}
