@use '@angular/material' as mat;

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
$primary: #4d6076;
$primary-1: #a2b2ff;
$primary-2: #7a839e;
$white: #ffffff;
$title: #22457d;
$title-1: #562ad8;
$title-2: #19233b;
$red: #43d796;
$secondary-1: #00e9fe;
$gradient: linear-gradient(to top, #ddfdfb 0%, #fff1f7 100%);
$dark-dividers: rgba(black, 0.12);
$dark-disabled-text: rgba(black, 0.38);
$dark-secondary-text: rgba(black, 0.54);
$dark-focused: rgba(black, 0.12);

$ifelse-primary: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78909c,
  500: #4d6076,
  600: #47454e,
  700: #37353e,
  800: #1f1d26,
  900: #1f1d26,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #1c1c4b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$ifelse-accent: (
  50: #d5ccee,
  100: #b7a9e0,
  200: #7961c3,
  300: #744de7,
  400: #6238de,
  500: #562ad8,
  600: #4020a0,
  700: #29126d,
  800: #20114c,
  900: #180f32,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #1c1c4b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$ifelse-primary-palette: mat.define-palette($ifelse-primary, 800, 300, 900);
$ifelse-accent-palette: mat.define-palette(mat.$pink-palette, 500);
$ifelse-warn-palette: mat.define-palette(mat.$pink-palette, 600);
$ifelse-success-palette: mat.define-palette(mat.$teal-palette, 400);

// Foreground palette for light themes.
$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba(black, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  hint-text: $dark-disabled-text,
  secondary-text: $dark-secondary-text,
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgba(black, 0.87),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
  accent-card: rgba(white, 0.26),
);

// Background palette for light themes.
$mat-light-theme-background: (
  status-bar: map_get(mat.$gray-palette, 300),
  app-bar: white,
  background: map_get(mat.$gray-palette, 50),
  hover: rgba(black, 0.04),
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: $dark-focused,
  selected-button: map_get(mat.$gray-palette, 300),
  selected-disabled-button: map_get(mat.$gray-palette, 400),
  disabled-button-toggle: map_get(mat.$gray-palette, 200),
  unselected-chip: map_get(mat.$gray-palette, 300),
  disabled-list-option: map_get(mat.$gray-palette, 200),
  tooltip: map_get(mat.$gray-palette, 700),
  widget: white,
  accent-card: map_get($ifelse-primary-palette, 50),
  accent-card-strong: map_get($ifelse-primary-palette, 50),
  dialog-accent: white,
);

$custom-typography: mat.define-typography-config(
  $font-family: 'Poppins, monospace',
  $body-1: mat.define-typography-level(14px, 24px, 500),
  $body-2: mat.define-typography-level(14px, 24px, 500),
  $headline-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-3: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-2: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-1: mat.define-typography-level(34px, 40px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
);

$ifelse-light-theme: map-merge(
  mat.define-light-theme(
    (
      color: (
        primary: $ifelse-primary-palette,
        accent: $ifelse-accent-palette,
        warn: $ifelse-warn-palette,
      ),
      typography: $custom-typography,
    )
  ),
  (
    is-dark: false,
    success: $ifelse-success-palette,
    foreground: $mat-light-theme-foreground,
    bg: $mat-light-theme-background,
  )
);
@mixin light-improvements($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, bg);

  trader-ticker {
    background-color: transparent;
  }
  .mat-drawer.order-drawer,
  .mat-mdc-dialog-container,
  .mat-bottom-sheet-container,
  .favorite-ticker-sidenav {
    background: $gradient;
  }
  .side-navigation {
    .mat-mdc-subheader {
      // background-color: mat.get-color-from-palette($background, accent-card);
    }
  }
  .mat-datepicker-content {
    background-color: white !important;
  }

  .ngx-slider {
    .ngx-slider-bar {
      background-color: mat.get-color-from-palette($foreground, secondary-text);
    }
    .ngx-slider-pointer {
      background-color: mat.get-color-from-palette($accent, 100) !important;

      &:after {
        background-color: mat.get-color-from-palette($accent, 300) !important;
      }
      &.ngx-slider-active:after {
        background-color: mat.get-color-from-palette($accent, 800) !important;
      }
    }
    .ngx-slider-bubble {
      color: mat.get-color-from-palette($foreground, text) !important;
      &.ngx-slider-limit {
        color: mat.get-color-from-palette($foreground, secondary-text) !important;
      }
    }
  }
}
