@use '@angular/material' as mat;

@mixin trader-symbol-server-order-list-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, bg);

  trader-symbol-server-order-list {
    .mat-expansion-panel-header,
    .mat-expansion-panel-header.mat-expanded:focus,
    .mat-expansion-panel-header.mat-expanded:hover {
      background-color: mat.get-color-from-palette($background, accent-card-strong) !important;
    }
    .mat-expansion-panel-body {
      background-color: mat.get-color-from-palette($background, accent-card-strong);
    }
  }
}
