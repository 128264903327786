@use '@angular/material' as mat;

@mixin trader-order-tags-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, bg);

  trader-order-tags,
  trader-batch-order-tags {
    .mat-mdc-chip.trader-accent,
    .mat-mdc-chip.mat-accent {
      background-color: mat.get-color-from-palette($accent) !important;
      color: mat.get-contrast-color-from-palette($accent, 500) !important;
      .mat-icon {
        color: mat.get-contrast-color-from-palette($accent, 500) !important;
      }
    }
  }
}
