@use '@angular/material' as mat;

@mixin widget-box-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, bg);

  trader-widget-box {
    background: mat.get-color-from-palette($background, widget);
  }
}
