@use '@angular/material' as mat;

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
$light-disabled-text: rgba(white, 0.5);
$light-focused: rgba(white, 0.12);
$dark-dividers: rgba(black, 0.12);
$dark-disabled-text: rgba(black, 0.38);
$dark-secondary-text: rgba(black, 0.54);
$dark-focused: rgba(black, 0.12);
$dark-card: #47454e;
$dark-app-bar: #1f1d26;

$ifelse-dark: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78909c,
  500: #403e49,
  600: #47454e,
  700: #37353e,
  800: #1f1d26,
  900: #1f1d26,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #1c1c4b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$black-success: (
  50: #d0efe4,
  100: #d0efe4,
  200: #aed9ca,
  300: #76a796,
  400: #87b7a6,
  500: #659987,
  600: #629986,
  700: #568d7a,
  800: #467967,
  900: #3d6b5b,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);
$mat-dark-theme-background: (
  status-bar: black,
  app-bar: map_get($ifelse-dark, 800),
  background: map_get($ifelse-dark, 500),
  hover: rgba(white, 0.04),
  card: map_get($ifelse-dark, 700),
  dialog: map_get($ifelse-dark, 700),
  disabled-button: rgba(white, 0.12),
  raised-button: map-get($ifelse-dark, 800),
  focused-button: $light-focused,
  selected-button: map_get($ifelse-dark, 800),
  selected-disabled-button: map_get($ifelse-dark, 800),
  disabled-button-toggle: black,
  unselected-chip: map_get($ifelse-dark, 700),
  disabled-list-option: black,
  tooltip: map_get($ifelse-dark, 700),
  widget: map_get($ifelse-dark, 800),
  accent-card: map_get($black-success, 700),
  accent-card-strong: map_get($black-success, 900),
  dialog-accent: #52907a,
);

$mat-dark-theme-foreground: (
  base: rgba(255, 255, 255, 0.8),
  divider: rgba(white, 0.1),
  dividers: rgba(white, 0.1),
  disabled: $light-disabled-text,
  disabled-button: rgba(white, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: rgba(255, 255, 255, 0.4),
  icon: white,
  icons: white,
  text: rgba(255, 255, 255, 0.8),
  slider-min: white,
  slider-off: rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
  accent-card: rgba(255, 255, 255, 0.8),
);

$ifelse-black-primary: mat.define-palette(mat.$amber-palette, 600);
$ifelse-black-accent: mat.define-palette(mat.$amber-palette, 600);
$ifelse-black-warn: mat.define-palette(mat.$red-palette, 500);
$ifelse-success: mat.define-palette(mat.$green-palette, 400);

$ifelse-dark-theme-private: mat.define-dark-theme(
  (
    color: (
      primary: $ifelse-black-primary,
      accent: $ifelse-black-accent,
      warn: $ifelse-black-warn,
    ),
  )
);
$ifelse-dark-theme: map_merge(
  $ifelse-dark-theme-private,
  (
    bg: $mat-dark-theme-background,
    success: $black-success,
    foreground: $mat-dark-theme-foreground,
  )
);

$forms-black-primary: mat.define-palette(mat.$amber-palette, 600);
$forms-black-accent: mat.define-palette(mat.$amber-palette, 600);
$forms-black-warn: mat.define-palette(mat.$red-palette, 500);
$forms-black-theme: mat.define-dark-theme(
  (
    color: (
      primary: $forms-black-primary,
      accent: $forms-black-accent,
      warn: $forms-black-warn,
    ),
  )
);
@mixin dark-improvements($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);
  $background: map-get($theme, bg);
  $foreground: map-get($theme, foreground);

  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: black;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field-error,
  .mat-mdc-form-field-invalid .mat-input-element,
  .mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
    color: mat.get-color-from-palette($warn, 200);
  }

  .app-wrapper {
    background: linear-gradient(
      180deg,
      rgb(31, 29, 38) 0%,
      rgba(64, 62, 73, 1) 75%,
      #5b8286
    ) !important;
  }
  .mat-bottom-sheet-container,
  .mat-mdc-card,
  .mat-mdc-table,
  .mat-expansion-panel {
    background-color: $dark-card;
  }

  --mdc-dialog-container-color: #47454e;
  --mat-stepper-container-color: #47454e;

  .mat-mdc-chip.mat-mdc-standard-chip,
  .mat-drawer-container {
    background-color: mat.get-color-from-palette($background, card);
  }

  .mat-drawer {
    background-color: $dark-app-bar;
    &.mat-drawer-push {
      background-color: $dark-app-bar;
    }
  }

  trader-server-order {
    .mat-expansion-panel-header.mat-expanded:hover {
      background-color: $dark-app-bar !important;
    }

    .mat-expansion-panel-body {
      background: linear-gradient(180deg, $dark-app-bar 0%, transparent 25%);
    }
  }
  .side-navigation {
    .mat-mdc-subheader {
      background-color: mat.get-color-from-palette($background, card);
    }
  }

  .mat-datepicker-content {
    background-color: mat.get-color-from-palette($background, card) !important;
    color: white !important;

    .mat-calendar-body-cell {
      color: white;
    }
  }

  .ngx-slider {
    .ngx-slider-bar {
      background-color: mat.get-color-from-palette($foreground, secondary-text) !important;
    }
    .ngx-slider-pointer {
      background-color: mat.get-color-from-palette($background, background) !important;

      &:after {
        background-color: mat.get-color-from-palette($accent, 300) !important;
      }
      &.ngx-slider-active:after {
        background-color: mat.get-color-from-palette($accent, 800) !important;
      }
    }
    .ngx-slider-bubble {
      color: mat.get-color-from-palette($foreground, text);
      &.ngx-slider-limit {
        color: mat.get-color-from-palette($foreground, secondary-text);
      }
    }
  }
}

@mixin dark-form-controls() {
  --mdc-filled-text-field-container-color: transparent !important;

  // @include mat.autocomplete-color($ifelse-dark-theme);
  // @include mat.card-color($ifelse-dark-theme);
  // @include mat.form-field-color($ifelse-dark-theme);
  .mat-mdc-input-element {
    caret-color: white !important;
  }
}
