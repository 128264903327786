@use '@angular/material' as mat;

@mixin bottom-drawer-theme($theme) {
  $background: map-get($theme, bg);
  $foreground: map-get($theme, foreground);

  trader-account-data-container {
    .mat-mdc-table {
      background-color: mat.get-color-from-palette($background, dialog);
    }
  }
  .bottom-drawer-panel {
    overflow-y: hidden;
  }
}
