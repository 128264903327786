@use '@angular/material' as mat;

@mixin ngx-slider-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  //$custom-typography: mat.define-typography-level(
  //  $font-family: 'Roboto, monospace',
  //  $body-1: mat.define-typography-level(12px, 18px, 500),
  //  $body-2: mat.define-typography-level(12px, 18px, 500),
  //);
  .ngx-slider {
    top: 17px;
    .ngx-slider-bar {
      height: 2px;
      margin-top: 2px;
    }
    .ngx-slider-bubble {
      font-size: 0.8rem;
    }
  }
}
