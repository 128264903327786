@use '@angular/material' as mat;

@mixin favourite-ticker-list-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, bg);

  trader-favourite-ticker-list {
    .delete-basket.active {
      background-color: mat.get-color-from-palette($warn, 500);
      color: mat.get-contrast-color-from-palette($warn, 500);
    }
    .delete-basket-group.cdk-drop-list-dragging {
      .delete-basket.active {
        border-color: mat.get-contrast-color-from-palette($warn, 600);
        color: mat.get-contrast-color-from-palette($warn, 600);
      }
    }
    #favourite-ticker-list {
      trader-symbol-select {
        background: mat.get-color-from-palette($background, app-bar);
      }
      trader-ticker {
        &.favourite.active {
          background: mat.get-color-from-palette($background, card) !important;
        }
        border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
        &:hover {
          border-left-color: mat.get-color-from-palette($foreground, secondary-text);
        }
      }
    }
    .placeholder {
      border-color: mat.get-contrast-color-from-palette($warn, 600);
    }
  }
}
