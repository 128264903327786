@use '@angular/material' as mat;

@mixin performance-overview-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, bg);
  $foreground: map-get($theme, foreground);

  trader-performance-overview {
    h2 {
      color: mat.get-color-from-palette($foreground, 'text');
    }
    h4 {
      color: mat.get-color-from-palette($foreground, 'text');
    }
    .table-wrapper {
      background-color: mat.get-color-from-palette($background, dialog);
      .mat-mdc-header-cell {
        background-color: mat.get-color-from-palette($background, dialog);
      }
    }
  }
}
