@use 'sass:map';
@use '@angular/material' as mat;

@mixin app-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, bg);
  $foreground: map-get($theme, foreground);

  mat-sidenav-container {
    mat-toolbar {
      background-color: mat.get-color-from-palette($background, app-bar) !important;
      color: mat.get-color-from-palette($foreground, text);

      height: 62px;
      button {
        &.active {
          color: mat.get-color-from-palette($accent, default-contrast);
          background-color: mat.get-color-from-palette($accent);
        }
      }

      .link {
        color: mat.get-color-from-palette($primary, default-contrast);
      }
    }

    mat-sidenav {
      .mat-mdc-list-item {
        &.active {
          --mdc-list-list-item-label-text-color: white;
          background-color: mat.get-color-from-palette($accent) !important;

          &:hover {
            --mdc-list-list-item-hover-label-text-color: white;
            background-color: mat.get-color-from-palette($accent, darker) !important;
          }
        }
      }
    }

    .footer {
      color: mat.get-color-from-palette($foreground, secondary-text);
      background-color: mat.get-color-from-palette($background, card);

      .links,
      .signature {
        a {
          color: mat.get-color-from-palette($primary, lighter);
          &:hover {
            color: mat.get-color-from-palette($accent);
          }
        }
      }
    }
  }

  .error-notification-overlay {
    color: mat.get-color-from-palette($warn, default-contrast);
    background-color: mat.get-color-from-palette($warn);
  }

  .info-notification-overlay {
    color: mat.get-color-from-palette($accent, default-contrast);
    background-color: mat.get-color-from-palette($accent);
  }

  .success-notification-overlay {
    color: mat.get-color-from-palette($success-colors, default-contrast);
    background-color: mat.get-color-from-palette($success-colors);
  }

  .warning-notification-overlay {
    color: mat.get-color-from-palette($warning-colors, default-contrast);
    background-color: mat.get-color-from-palette($warning-colors);
  }

  /* Scroll bar */
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    color: mat.get-color-from-palette($primary) !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    color: mat.get-color-from-palette($primary, 700) !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
  }
}
