@mixin trader-exchange-orders-list-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, bg);

  trader-exchange-orders-list {
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
    mat-card.wrapper {
      background-color: mat.get-color-from-palette($background, accent-card);
    }
  }
}
