@use '@angular/material' as mat;
@import '../../../../../styles/variables';

@mixin sellColors($color) {
  background-color: mat.get-color-from-palette($color);
  color: mat.get-color-from-palette($color, '600-contrast');
}

@mixin buyColors($color) {
  background-color: mat.get-color-from-palette($color);
  color: mat.get-color-from-palette($color, '600-contrast');
}

@mixin server-order-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, bg);

  trader-server-order {
    .buy-button {
      @include buyColors($success);
    }

    .sell-button {
      @include sellColors($warn);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-toggle-checked {
      &.buy-button {
        @include buyColors($success);
      }

      &.sell-button {
        @include sellColors($warn);
      }
    }

    .quantity-buttons {
      button {
        background-color: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, '600-contrast');
      }
    }
  }

  trader-server-order-confirmation-dialog,
  trader-batch-order-confirmation-content {
    .counter {
      background: mat.get-color-from-palette($foreground, text);
      color: mat.get-color-from-palette($background, card) !important;
    }
    .validation-icon {
      .valid {
        color: mat.get-color-from-palette($success, 500);
      }
      .error {
        color: mat.get-color-from-palette($warn, 500);
      }
      .warning {
        color: mat.get-color-from-palette($accent, 600);
      }
    }
  }

  trader-server-order-confirmation-dialog {
    trader-order-tags {
      .mat-mdc-chip {
        background-color: mat.get-color-from-palette($foreground, divider) !important;
        color: mat.get-color-from-palette($foreground, text) !important;
        .mat-icon {
          color: mat.get-color-from-palette($foreground, text) !important;
        }
      }
    }
  }
}
