@use '@angular/material' as mat;

@mixin trader-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  //$custom-typography: mat.define-typography-level(
  //  $font-family: 'Roboto, monospace',
  //  // $headline: mat-typography-level(32px, 48px, 700),
  //  $body-1: mat.define-typography-level(12px, 18px, 500),
  //  $body-2: mat.define-typography-level(12px, 18px, 500),
  //);
  // @include mat-table-typography($custom-typography);
  .mat-mdc-paginator {
    background: transparent;
  }
}
