.trader-select-symbol {
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.cdk-overlay-pane.asset-pair-select-wrapper {
  /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
  .mat-autocomplete-panel {
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: 60vh !important;
  }
}
