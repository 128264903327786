@use '@angular/material' as mat;

@mixin batch-order-confirmation-content-theme($theme) {
  $background: map-get($theme, bg);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, bg);

  trader-batch-order-confirmation-content {
    .counter {
      background: mat.get-color-from-palette($foreground, text);
      color: mat.get-color-from-palette($background, card) !important;
    }
    .validation-icon {
      .valid {
        color: mat.get-color-from-palette($success, 500);
      }
      .error {
        color: mat.get-color-from-palette($warn, 500);
      }
      .warning {
        color: mat.get-color-from-palette($accent, 600);
      }
    }
  }
}
