@use '@angular/material' as mat;

@mixin sellColors($color) {
  background-color: mat.get-color-from-palette($color);
  color: mat.get-color-from-palette($color, '600-contrast');
}

@mixin buyColors($color) {
  background-color: mat.get-color-from-palette($color);
  color: mat.get-color-from-palette($color, '600-contrast');
}

@mixin batch-order-templates-theme($theme) {
  $background: map-get($theme, bg);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, bg);

  trader-batch-orders-templates {
    trader-server-order {
      .mat-expansion-panel-header {
        background-color: mat.get-color-from-palette($background, app-bar);
      }
      .mat-expansion-panel-body {
        background: linear-gradient(
          180deg,
          mat.get-color-from-palette($background, app-bar) 0%,
          transparent 25%
        );
      }
      .buy-button {
        @include buyColors($success);
      }

      .sell-button {
        @include sellColors($warn);
      }
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-checked {
        &.buy-button {
          @include buyColors($success);
        }

        &.sell-button {
          @include sellColors($warn);
        }
      }

      .quantity-buttons {
        button {
          background-color: mat.get-color-from-palette($primary);
          color: mat.get-color-from-palette($primary, '600-contrast');
        }
      }

      .filter-group .divider {
        border-color: rgba(180, 180, 180, 0.22);
      }
    }
    .add-filter-wrapper {
      background-color: mat.get-color-from-palette($background, accent-card-strong);
    }
  }

  trader-server-order-confirmation-dialog {
    .validation-icon {
      .valid {
        color: mat.get-color-from-palette($success, 500);
      }
      .error {
        color: mat.get-color-from-palette($warn, 500);
      }
      .warning {
        color: mat.get-color-from-palette($accent, 600);
      }
    }
  }
}
