@use '@angular/material' as mat;

@mixin order-side-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);

  trader-order-side {
    &.BUY {
      background-color: mat.get-color-from-palette($success, 600);
      color: mat.get-contrast-color-from-palette($success, 600);
    }
    &.SELL {
      background-color: mat.get-color-from-palette($warn, 600);
      color: mat.get-contrast-color-from-palette($warn, 600);
    }
  }
}
