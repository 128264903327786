@use '@angular/material' as mat;

@mixin directives-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);

  $negative: mat.get-color-from-palette($warn, 500);
  $positive: mat.get-color-from-palette($success, 400);

  .trader-positive {
    color: $positive;
  }
  .trader-negative {
    color: $negative;
  }
  .trader-background-positive {
    color: mat.get-contrast-color-from-palette($success, 500) !important;
    background-color: $positive;
  }
  .trader-background-negative {
    background-color: $negative;
    color: mat.get-contrast-color-from-palette($warn, 500) !important;
  }
}
