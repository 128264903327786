@use '@angular/material' as mat;

@mixin portfolio-overview-dropdown-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, bg);
  $foreground: map-get($theme, foreground);

  trader-portfolio-overview-dropdown {
    .trigger .secondary {
    }
  }
  .portfolio-overview-dropdown-wrapper {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .portfolio-overview-dropdown-panel {
    background-color: mat.get-color-from-palette($background, card);
  }
}
