@use '@angular/material' as mat;

@mixin symbol-toolbar-theme($theme) {
  $primary: mat.get-color-from-palette($theme, primary);
  $accent: mat.get-color-from-palette($theme, accent);
  $warn: mat.get-color-from-palette($theme, warn);
  $background: mat.get-color-from-palette($theme, background);
  $foreground: mat.get-color-from-palette($theme, foreground);

  .star {
    color: rgba(0, 0, 0, 0.22);
    &.favorite {
      color: mat.get-color-from-palette($accent, 500);
    }
  }
  trader-asset-pair-select {
    .markets {
      button {
        background-color: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($foreground, text);
        &.selected {
          background-color: mat.get-color-from-palette($accent);
          color: mat.get-contrast-color-from-palette($accent, 500);
        }
      }
    }
  }
}
