@use '@angular/material' as mat;

@mixin grow-indicator($theme) {
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);
  $background: map-get($theme, bg);

  trader-grow-indicator {
    .up {
      color: mat.get-color-from-palette($success, 700);
    }
    .down {
      color: mat.get-color-from-palette($warn, 700);
    }
    .contrast {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}
