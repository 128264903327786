@use '@angular/material' as mat;

@mixin server-orders-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $success: map-get($theme, success);

  // $custom-typography: mat-typography-config(
  //   $font-family: 'Roboto, monospace',
  //   $headline: mat-typography-level(32px, 48px, 700),
  //   $body-1: mat-typography-level(12px, 18px, 500),
  //   $body-2: mat-typography-level(12px, 18px, 500),
  // );
  // @include mat-table-typography($custom-typography);

  trader-server-orders-table {
    .bullet {
      background-color: mat.get-color-from-palette($warn, 100);
      color: mat.get-contrast-color-from-palette($warn, 100);
    }
  }
}
